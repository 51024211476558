import React from "react";

export default function ContactSection() {
  return (
    <>
      <section id="contact" className="agents">
        <div className="container">
          <div className="row agents-services">
            <div className="col-12 col-md-5  agents-title">
              <h3>Contáctanos</h3>
              <p>
                No pierdas la oportunidad de asegurar tu propio pedazo de
                paraíso. Contáctanos hoy mismo para obtener más información y
                dar el primer paso hacia tu futuro. Nuestro equipo experto está
                listo para ayudarte en cada paso del camino. ¡No esperes más, tu
                nuevo comienzo te espera!
              </p>
              <p>
                Isidro Gallegos y Feliciano Checa
                <br />
                <strong>Teléfono: </strong>
                <a
                  href="https://wa.me/593998147013?text=Hola%20estoy%20interesado%20en%20mas%20informaci%C3%B3n%20de%20terrenos."
                  target="_blank"
                  rel="noreferrer"
                >
                  (+593) 09950 26463
                  <br />
                </a>
                Riobamba, Ecuador
                <br />
              </p>
              <p>
                <a
                  href="https://wa.me/593998147013?text=Hola%20estoy%20interesado%20en%20mas%20informaci%C3%B3n%20de%20terrenos."
                  title="Contactanos por WhatsApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="contact-whatsapp-button"
                    src="macaji/images/whatsapp.svg"
                    alt="WhatsApp"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@san.francisco.de.macaji"
                  title="Contactanos por Tik Tok"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="contact-whatsapp-button"
                    src="macaji/images/tik-tok.svg"
                    alt="WhatsApp"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
