import React from "react";

export default function HeaderSection() {
  return (
      <header id="home">
        <link rel="icon" href="macaji/images/land-icon.png"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="theme-color" content="#000000"/>
        <meta
            name="description"
            content="Venta de lotes cerca de la ESPOCH, Riobamba - Ecuador"
        />
        <nav className="animate">
          <div className="container">
            <div className="logo left">
              <a href="#home">
                {" "}
                {/*<img src="macaji/images/land-icon.png" className="icon"/> San F.*/}
                {/*de Macají 6*/}
                <img src="macaji/images/land-icon.png" className="icon"/> Francisco Vinueza
              </a>
            </div>
            <div className="menu-button hide right pointer">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="menu left">
              <div className="page-menu left">
                <li>
                  <a href="#home">Inicio</a>
                </li>
                <li>
                  <a href="#about">Nosotros</a>
                </li>
                <li>
                  <a href="#lote">Lotes</a>
                </li>
                <li>
                  <a href="#works">Reservación</a>
                </li>
                <li>
                  <a href="#oportunity">Oportunidad</a>
                </li>
                <li>
                  <a href="#features">Características</a>
                </li>
              </div>
              <div className="registration flex-center">
                <div className="getting-started">
                  <li className="main-btn pointer text-center animate">
                    <a href="#contact">Contáctanos</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="hero">
          <div className="container">
            <div className="hero-wrapper">
              <div className="title">
                <h1>
                  Tu terreno
                  <br/>
                  está aquí{" "}
                </h1>
              </div>
              <div className="hero-image">
                <iframe
                    width="560"
                    height="315"
                    className="google-map"
                    src="https://www.youtube.com/embed/oS1t4daan8k?playlist=oS1t4daan8k&amp;controls=0&amp;autoplay=1&amp;loop=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; loop;"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
                <div className="hero-image-info yellow-bg flex-center">
                  <div className="info flex-center">
                    <img src="macaji/images/call-icon.svg" alt=""/>
                    <p>(+593) 099 5026 463</p>
                  </div>
                  <div className="info flex-center">
                    <img src="macaji/images/point-icon.svg" alt=""/>
                    <p>Riobamba, Ecuador</p>
                  </div>
                </div>
              </div>
              <div className="slide-down text-center pointer">
                <a href="#about">
                  <p>Deslizar abajo</p>
                  <img src="macaji/images/scroll.svg" alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}
