export default function Policy() {
  return (
      <>
          <h4>Privacy Policy</h4>
          <p>This Privacy Policy governs the manner in which <strong>macaji.com</strong> collects, uses, maintains, and
              discloses information collected from users of the web application.</p>

          <h6>Information We Collect</h6>
          <p><strong>Macaji.com</strong> utilizes the Facebook API to provide certain features and functionalities
              within the app. When you use <strong>macaji.com</strong>, we may collect and use the following
              information:</p>
          <ul>
              <li><strong>Public Profile Information:</strong> We may collect your public profile information from
                  Facebook, such as your name, profile picture, and any other information that you have made publicly
                  available on Facebook.
              </li>
              <li><strong>Friends List:</strong> With your consent, we may access your friends list on Facebook to
                  enhance your experience within the app. We do not store this information.
              </li>
              <li><strong>User Interactions:</strong> We may collect information about your interactions with the app,
                  such as the actions you take and the content you view.
              </li>
          </ul>

          <h6>How We Use Information</h6>
          <p><strong>Macaji.com</strong> uses the information collected from Facebook for the following purposes:</p>
          <ul>
              <li><strong>To Enhance User Experience:</strong> We use your public profile information to personalize
                  your experience within the app and to provide you with relevant content.
              </li>
              <li><strong>To Improve Our Services:</strong> We may analyze user interactions to understand how users
                  interact with the app and to make improvements accordingly.
              </li>
          </ul>

          <h6>Data Storage</h6>
          <p><strong>Macaji.com</strong> does not store any user data obtained from Facebook. All data retrieved from
              Facebook's API is used in real-time to provide the requested features and functionalities within the app.
              We do not retain this information after your session ends.</p>

          <h6>Third-Party Access</h6>
          <p><strong>Macaji.com</strong> does not share your information with any third parties. The data obtained from
              Facebook's API is used solely for the purposes outlined in this Privacy Policy.</p>

          <h6>Data Security</h6>
          <p>We are committed to ensuring the security of your information. We employ industry-standard security
              measures to protect the data collected from Facebook's API.</p>

          <h6>Changes to This Privacy Policy</h6>
          <p><strong>Macaji.com</strong> reserves the right to update this Privacy Policy at any time. We encourage
              users to frequently check this page for any changes. You acknowledge and agree that it is your
              responsibility to review this Privacy Policy periodically and become aware of modifications.</p>

          <h6>Your Consent</h6>
          <p>By using <strong>macaji.com</strong>, you signify your acceptance of this Privacy Policy. If you do not
              agree to this policy, please do not use our app. Your continued use of the app following the posting of
              changes to this policy will be deemed your acceptance of those changes.</p>

          <h6>Contact Us</h6>
          <p>If you have any questions about this Privacy Policy, the practices of <strong>Macaji.com</strong>, or your
              dealings with the app, please contact us.</p>

      </>
  );
}
