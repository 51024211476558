import React from "react";

export default function LocationSection() {
  return (
    <>
      <section id="location" className="appartments">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Ubicación</h2>
              <p>
                Nos encontramos al frente de la ESPOCH, lugar estratégico para
                tu inversión.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="appartment-image">
                <iframe
                    title="Google map for Macaji.com"
                  className="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d5166.919394200995!2d-78.67983659116508!3d-1.6645221487123976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMzknNDkuNSJTIDc4wrA0MCc0MS4wIlc!5e1!3m2!1sen!2sat!4v1709888560069!5m2!1sen!2sat"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
