import React from "react";

export default function LoteSection() {
  return (
    <>
      <section id="lote" className="appartments">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Lotes</h2>
              <p>Elije el lote que mas se adapte a tus necesidades.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="appartment-image">
                <a
                  href="macaji/images/planos.png"
                  title="Ver planos en grande"
                  target="_blank"
                >
                  <img src="macaji/images/planos.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
