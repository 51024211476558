import React from "react";

export default function HowToBuySection() {
  return (
    <>
      <section id="works" className="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12 works-title">
              <h3>Reservación</h3>
              <p>
                No dejes que el futuro pase de largo. Reserva ahora y empieza a
                construir la vida que mereces.
                <br />
                ¡Contáctanos hoy y descubre el comienzo de tu próxima aventura!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-4">
              <div className="work-box animate">
                <div className="work-box-number">
                  <p>. 1</p>
                </div>
                <div className="work-box-title">
                  <p className="left">1</p>
                  <p className="left">Elije el terreno</p>
                </div>
                <div className="work-box-text">
                  <p>
                    En este página web podrás encontrar diferentes terrenos.
                    Elije el terreno que mas se adapte a tus necesidades.
                  </p>
                </div>
                <div className="work-box-link">
                  <a href="/macaji/images/planos.png" target="_blank">
                    Ver
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="work-box animate">
                <div className="work-box-number">
                  <p>. 2</p>
                </div>
                <div className="work-box-title">
                  <p className="left">2</p>
                  <p className="left">Contáctate con nosotros</p>
                </div>
                <div className="work-box-text">
                  <p>
                    Haz nos saber cual es el terreno que mas te gusta para poder
                    reservarlo.
                  </p>
                </div>
                <div className="work-box-link">
                  <a
                    target="_blank"
                    href="https://wa.me/593998147013?text=Hola%20estoy%20interesado%20en%20mas%20informaci%C3%B3n%20de%20terrenos." rel="noreferrer"
                  >
                    Contáctanos
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="work-box animate">
                <div className="work-box-number">
                  <p>. 3</p>
                </div>
                <div className="work-box-title">
                  <p className="left">3</p>
                  <p className="left">Compromiso de compra y venta</p>
                </div>
                <div className="work-box-text">
                  <p>
                    Firma el compromiso de compra y venta para asegurar tu
                    terreno.
                  </p>
                </div>
                <div className="work-box-link">
                  <a href="#"></a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="work-box animate">
                <div className="work-box-number">
                  <p>. 4</p>
                </div>
                <div className="work-box-title">
                  <p className="left">4</p>
                  <p className="left">Financiamiento</p>
                </div>
                <div className="work-box-text">
                  <p>
                    Te ofrecemos financiamiento con bancos/cooperativas de
                    ahorro y crédito y en cómodas cuotas.
                  </p>
                </div>
                <div className="work-box-link">
                  <a href="#"></a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="work-box animate">
                <div className="work-box-number">
                  <p>. 5</p>
                </div>
                <div className="work-box-title">
                  <p className="left">5</p>
                  <p className="left">Entrega de terreno</p>
                </div>
                <div className="work-box-text">
                  <p>Te entregamos tu terreno listo para construir.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="oportunity" className="how-it-works">
        <div className="container">
          <div className="row work-services">
            <div className="col-12 work-services-title">
              <h3>Oportunidades</h3>
              <p>
                ¡Descubre las emocionantes oportunidades de negocio que te
                esperan al adquirir uno de nuestros terrenos exclusivos!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-2 col-md-7">
              <div className="work-service-image">
                <video
                  className="work-service-video"
                  // width="650"
                  height="800"
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  controls={false}
                  poster="macaji/images/pre-load-video-1.jpg"
                >
                  <source
                    src="macaji/videos/oportunidad-negocio.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="col-12 col-md-5 work-service-info">
              <h4>Viviendas</h4>
              <p>
                ¡Construye el hogar de tus sueños y conviértete en propietario!
                Nuestros terrenos ofrecen la base perfecta para crear
                comunidades vibrantes y acogedoras, con una variedad de opciones
                desde casas unifamiliares hasta apartamentos de lujo. ¡Haz
                realidad tus sueños y brinda hogares a familias ansiosas por
                construir recuerdos inolvidables!
              </p>

              <h4>Oficinas y Espacios Coworking</h4>
              <p>
                ¿Buscas un lugar para establecer tu negocio o crear un espacio
                colaborativo? Nuestros terrenos son ideales para la construcción
                de oficinas modernas y espacios coworking que fomentan la
                innovación y el crecimiento empresarial. ¡Sé parte de una
                comunidad de profesionales creativos y visionarios!
              </p>

              <h4>Viviendas para Estudiantes</h4>
              <p>
                ¿Buscas una oportunidad de negocio en el sector estudiantil?
                Construye viviendas exclusivas para estudiantes cerca de
                universidades y colegios. ¡Ofrece comodidad y seguridad a los
                estudiantes mientras generas ingresos estables y consistentes!
              </p>
              <h4>Zonas de Entretenimiento</h4>
              <p>
                Convierte tu terreno en un destino de entretenimiento de primer
                nivel, con restaurantes, cines, áreas de juegos y mucho más.
                ¡Crea momentos inolvidables para familias y amigos mientras
                generas ingresos en un ambiente vibrante y emocionante!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
