import React from "react";

export default function FeaturesSection() {
  return (
    <section id="features" className="add">
      <div className="container">
        <div className="row works-title">
          <div className="col-12 work-services-title">
            <h3>Características</h3>
            <p>
              Todos los terrenos cuentan con las siguientes características:
              <br></br>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-7">
            <div className="add-image add1">
              <div className="add-image-info flex-center">
                <img src="macaji/images/pointer.svg" className="left" alt="" />
                <p>ESPOCH</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 flex-center">
            <div className="add-info">
              <h4>Lugar estratégico</h4>
              <p>
                Las universidades son imanes para estudiantes, profesores y
                personal, asegurando una demanda constante de vivienda y
                servicios en la zona. Además, La presencia de la ESPOCH atrae el
                interés de desarrolladores, lo que puede llevar a un aumento del
                valor de los terrenos a medida que se desarrolla la
                infraestructura y los servicios cercanos.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-5 flex-center">
            <div className="add-info add2-info">
              <h4>Alumbrado público</h4>
              <p>
                Imagina construir tu hogar de ensueño o tu próximo proyecto
                comercial en un entorno donde la estética y la seguridad van de
                la mano. Con nuestro alumbrado público soterrado, disfrutarás de
                calles iluminadas de manera discreta y segura, realzando la
                belleza de tu entorno sin cables visibles.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="add-image right add2">
              <div className="add-image-info add-image-info2 flex-center">
                <img src="macaji/images/pointer.svg" className="left" alt="" />
                <p>Sin cables a la vista</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="add-image add3">
              <div className="add-image-info flex-center">
                <img src="macaji/images/pointer.svg" className="left" alt="" />
                <p>Libre de contaminación</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 flex-center">
            <div className="add-info">
              <h4>Agua potable</h4>
              <p>
                Imagina construir tu hogar de ensueño o tu próximo proyecto
                comercial en un lugar donde no tienes que preocuparte por la
                calidad o la disponibilidad del agua. Nuestros terrenos ofrecen
                esa tranquilidad y comodidad, asegurando que tengas acceso a
                agua potable de alta calidad desde el momento en que adquieres
                la propiedad.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-5 flex-center">
            <div className="add-info add2-info">
              <h4>Alcantarrillado</h4>
              <p>
                Imagina la conveniencia y tranquilidad de contar con un sistema
                de alcantarillado eficiente y moderno en tu propiedad. Olvídate
                de preocuparte por la gestión de aguas residuales y disfruta de
                un entorno limpio y seguro para tu familia o negocio.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="add-image right add4">
              <div className="add-image-info add-image-info2 flex-center">
                <img src="macaji/images/pointer.svg" className="left" alt="" />
                <p>Tubería de calidad</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="add-image add5">
              <div className="add-image-info flex-center">
                <img src="macaji/images/pointer.svg" className="left" alt="" />
                <p>Claro / Netlife / CNT</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 flex-center">
            <div className="add-info">
              <h4>Internet</h4>
              <p>
                Imagina construir tu hogar o tu negocio en un lugar donde la
                velocidad y la fiabilidad de la conexión a Internet son una
                garantía. Con nuestro cableado para Internet, podrás disfrutar
                de una conexión de alta velocidad para tus necesidades
                personales o empresariales, desde teletrabajo hasta
                entretenimiento en línea sin interrupciones.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
