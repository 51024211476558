import React from "react";

export default function FooterSection() {
  const whatsAppUrl =
    "https://wa.me/593998147013?text=Hola%20estoy%20interesado%20en%20mas%20informaci%C3%B3n%20de%20terrenos.";
  const tikTokUrl = "https://www.tiktok.com/@san.francisco.de.macaji";

  return (
    <footer id="contact">
      <div className="container">
        <div className="row footer-top">
          <div className="col-12 col-md-6">
            <img src="macaji/images/land-icon.png" className="icon-footer" />
            <h3>
              {" "}
              San Francisco <br />
              de Macají 6
            </h3>
          </div>
        </div>
        <div className="row footer-bottom">
          <div className="col-sm-12 col-md-4 footer-logo">
            <h4>SFdM6</h4>
            <p>
              © 2024 - SFdM6,
              <br />
              Todos los derechos reservados
            </p>
          </div>
          <div className="col-sm-3 col-md-2 footer-column">
            <h5>SFdM</h5>
            <a href="#home">Inicio</a>
            <a href="#about">Nosotros</a>
          </div>
          <div className="col-sm-3 col-md-2 footer-column">
            <h5>Terrenos</h5>
            <a href="#lote">Lotes</a>
            <a href="#works">Reservación</a>
            <a href="#oportunity">Oportunidad</a>
            <a href="#features">Características</a>
          </div>
          <div className="col-sm-3 col-md-2 footer-column">
            <h5>Contáctanos</h5>
            <a href={`${whatsAppUrl}`} target="_blank" rel="noreferrer">
              WhatsApp
            </a>
            <a href={`${tikTokUrl}`} target="_blank" rel="noreferrer">
              TikTok
            </a>
          </div>
          <div className="col-sm-3 col-md-2 footer-column">
            <a
              href={`${whatsAppUrl}`}
              title="Contactanos por WhatsApp"
              target="_blank" rel="noreferrer"
            >
              <img
                className="whatsapp-button"
                src="macaji/images/whatsapp.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
