import HeaderSection from "../components/sections/HeaderSection";
import StatisticSection from "../components/sections/StatisticSection";
import LoteSection from "../components/sections/LoteSection";
import HowToBuySection from "../components/sections/HowToBuySection";
import FeaturesSection from "../components/sections/FeaturesSection";
import FooterSection from "../components/sections/FooterSection";
import ContactSection from "../components/sections/ContactSection";
import LocationSection from "../components/sections/LocationSection";

export default function Landing() {
  return (
    <>
      <HeaderSection />
      <StatisticSection />
      <LoteSection />
      <LocationSection />
      <HowToBuySection />
      {/*<AgentSection />*/}
      <FeaturesSection />
      <ContactSection />
      <FooterSection />
    </>
  );
}
