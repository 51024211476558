import React from "react";

export default function StatisticSection() {
  return (
    <section className="statistic" id="about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>¡Atención inversionistas y soñadores del hogar!</h3>
            <p>
              ¿Buscas una inversión que también te brinde la oportunidad de
              construir la casa de tus sueños? ¡Estás en el lugar correcto!
              Nuestros terrenos no solo son una excelente oportunidad de
              inversión, sino también el lienzo perfecto para construir la casa
              que siempre has deseado. Imagina vivir en un lugar donde cada
              rincón refleje tu estilo de vida y personalidad. Con nuestros
              terrenos, tienes la libertad de diseñar y construir tu hogar desde
              cero, asegurando que cada detalle se ajuste a tus gustos y
              necesidades.
            </p>
            <p>
              Tenemos desde terrenos con impresionantes vistas hasta lotes cerca
              de servicios esenciales, tenemos la selección perfecta para ti. No
              pierdas la oportunidad de invertir en tu futuro y construir la
              vida que siempre has imaginado. ¡Contáctanos hoy mismo y comienza
              a hacer realidad tus sueños!
            </p>
          </div>
          <div className="col-12 col-lg-6 statistic-wrapper">
            <div className="statistic-box text-center">
              <p>26</p>
              <p>Lotes</p>
            </div>
            <div className="statistic-box text-center">
              <p>8</p>
              <p>
                Lotes <br /> Esquineros
              </p>
            </div>
            <div className="statistic-box text-center">
              <p>6991</p>
              <p>Metros Cuadrados</p>
            </div>
            <div className="statistic-box text-center">
              <p>5</p>
              <p>Manzanas</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
