import React from "react";
import "./App.css";
import Landing from "./screens/Landing";
import ReactGA from "react-ga4";
import Policy from "./screens/Policy";
import {BrowserRouter, Route, Routes} from "react-router-dom";

ReactGA.initialize("G-KX9FDTEEK3");

function App() {
  return (
    <>
       <BrowserRouter>
            <Routes>
                <Route path="/policy" element={<Policy />} />
                <Route index element={<Landing />} />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
